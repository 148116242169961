#Help .content {
  padding-right: 3em;
}

#Help p {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

#Help ul {
  padding-left: 1em;
}

#Help ul li {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
