.Background .powervault-plus {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/backgrounds/powervault-plus.png');
  opacity: 0.1;
  filter: blur(5px);
}

.Background .ellipse-1 {
  position: absolute;
  width: 773.54px;
  height: 773.54px;
  left: -248.21px;
  top: -148px;

  background: rgba(0, 157, 156, 0.6);
  filter: blur(200px);
}

.Background .ellipse-2 {
  position: absolute;
  width: 509.59px;
  height: 509.59px;
  left: 1018.56px;
  top: 873.67px;

  background: rgba(0, 157, 156, 0.8);
  filter: blur(200px);
}

.Background .ellipse-3 {
  position: absolute;
  width: 297.54px;
  height: 297.54px;
  left: 1249.01px;
  top: -58.77px;

  background: #009d9c;
  filter: blur(200px);
}

.Background .ellipse-4 {
  position: absolute;
  width: 354.14px;
  height: 354.14px;
  left: 58.56px;
  top: 951.4px;

  background: rgba(0, 157, 156, 0.8);
  filter: blur(200px);
}
