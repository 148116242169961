/*******************************
         Site Overrides
*******************************/

.ui.button.primary,
.ui.button.secondary {
    text-transform: uppercase !important;
}


/********************************
 Fix for inverted primary button
********************************/

.ui.inverted.primary.basic.buttons .button:hover, .ui.inverted.primary.buttons .basic.button:hover, .ui.inverted.primary.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #ffffff inset !important;
  color: #ffffff !important;
}
