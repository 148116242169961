.BetaProgramme {
}

.BetaProgramme div.container {
  text-align: right;
  margin-right: 1em;
  margin-top: 1rem;
}
.BetaProgramme div.container .label {
  cursor: pointer;
}
.BetaProgramme div.container.disabled .label {
  opacity: 0.9;
}
.BetaProgramme div.container.enabled .label {
  cursor: default;
}
