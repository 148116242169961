#settings {
  padding-bottom: 1.5rem;

  background-color: rgba(255, 255, 255, 0.2);
  color: #fff !important;
  box-shadow: 14px 14px 28px 0px #00000040;
  backdrop-filter: blur(10px);
}
#settings > .ui.header,
#settings > .ui.header .content {
  color: #fff !important;
}
#settings > .ui.header .content .sub.header {
  color: #fff !important;
  opacity: 0.8;
}

#settings div.custom-box {
  position: relative;
  min-height: 11em;
  padding-bottom: 4em;
}

/* title */
#settings div.custom-box p strong {
  font-size: 120%;
  margin-right: 1em;
}

#settings div.custom-box button,
#settings div.custom-box [role='button'] {
  position: absolute;
  bottom: 1em;
}

#settings span.clusters-desc {
  display: inline-block;
  opacity: 0.8;
}

#settings .label.status {
  vertical-align: top;
  padding-left: 1em !important;
  padding-right: 1em !important;
  margin-left: -0.2em;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1);
}
#settings .label.status.disabled {
  color: black;
}

#settings .label.status.light-text {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
}
