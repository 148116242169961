.PowervaultLogo {
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%) scale(90%);
}

@media only screen and (max-width: 767px) {
  .PowervaultLogo {
    transform: translate(-50%, -50%) scale(55%);
  }
}
