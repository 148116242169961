#eps-schedule-modal {
  margin-top: 0;
}

/* Modal Header */

#eps-schedule-modal > div.header {
  background-color: #f56100;
  color: white !important;
}

#eps-schedule-modal > div.header h2 {
  color: inherit;
  margin: 0;
}

/* Day Buttons */
#eps-schedule-modal .day-buttons {
}
#eps-schedule-modal .day-buttons button.today span {
  text-decoration: underline;
}

/* Schedule Items */

#eps-schedule-modal div.schedule-slot {
  position: relative;
  height: 60px;
  cursor: pointer;
  border-left-width: 11px !important;
  border-left-style: solid !important;
}
#eps-schedule-modal div.schedule-slot.inactive > * {
  opacity: 0.5;
}
#eps-schedule-modal div.schedule-slot.active {
  color: #2185d0;
  background-color: #eee;
}
#eps-schedule-modal div.schedule-slot.conflict {
  color: #9f3a38;
  background-color: #fff6f6;
}
#eps-schedule-modal div.schedule-slot.new-button {
  border: 0;
  border-left-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(224, 225, 226);
  color: rgba(0, 0, 0, 0.6);
}
#eps-schedule-modal div.schedule-slot.new-button.primary {
  color: #ffffff;
}
#eps-schedule-modal div.schedule-slot.new-button > div {
  padding-top: 6px;
}
#eps-schedule-modal .color.eps-enabled {
  border-left-color: #f56100 !important;
}
#eps-schedule-modal .color.eps-disabled {
  border-left-color: rgb(180, 180, 180) !important;
}

#eps-schedule-modal div.schedule-slot div.schedule {
  position: absolute;
  left: 30px;
  top: 0;
  height: 100%;
}
#eps-schedule-modal div.schedule-slot div.schedule h2.time {
  color: inherit;
  margin: 15px 0 3px 0;
}

#eps-schedule-modal div.schedule-slot div.state {
  position: absolute;
  left: 110px;
  top: 0;
  height: 100%;
  padding: 0 10px;
  text-align: center;
  display: table;
}
#eps-schedule-modal div.schedule-slot div.state > span {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}

#eps-schedule-modal div.schedule-slot button.negative {
  margin-top: -2px;
}

/* Schedule Detail */

#eps-schedule-modal div.schedule-detail {
  vertical-align: middle;
  min-height: 370px;
}
#eps-schedule-modal div.schedule-detail.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
}
#eps-schedule-modal div.schedule-detail.error .header {
  color: #9f3a38 !important;
}

#eps-schedule-modal div.schedule-detail.no-selection div.header,
#eps-schedule-modal div.schedule-detail.item-removed div.header,
#eps-schedule-modal div.schedule-detail.saving div.header {
  opacity: 0.5;
}

#eps-schedule-modal div.schedule-detail h2 {
  display: inline;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.7);
}

#eps-schedule-modal div.schedule-detail .state-container h2 {
  display: block;
  margin-bottom: 10px;
}

#eps-schedule-modal div.schedule-detail .state-container div.dropdown {
  display: inline-block;
  font-size: 150%;
  width: 12em;
  min-width: 12em;
  border-left-width: 11px;
  border-left-style: solid;
}
#eps-schedule-modal div.schedule-detail .state-container div.dropdown .item {
  padding: 14px !important;
  border-left-width: 11px;
  border-left-style: solid;
}

#eps-schedule-modal div.schedule-detail .spinner-container h2 {
  display: inline-block;
  margin-bottom: 43px;
}

#eps-schedule-modal div.schedule-detail .spinner-container .time-spinner {
  display: inline-block;
  margin-right: 10px;
}

#eps-schedule-modal div.schedule-detail .spinner-container .time-spinner button {
  display: block;
  width: 100%;
  margin: 4px 0;
}
#eps-schedule-modal div.schedule-detail .spinner-container .time-spinner div.input {
  font-size: 150%;
  width: 70px;
}

#eps-schedule-modal div.schedule-detail .reserve-capacity-container {
  margin-top: 2em;
}

#eps-schedule-modal div.schedule-detail .reserve-capacity-container h2 {
  display: inline-block;
  margin-bottom: 32px;
  text-align: right;
  padding-right: 0.5em;
}

#eps-schedule-modal div.schedule-detail .reserve-capacity-container .percent-spinner {
  display: inline-block;
  margin-right: 10px;
}

#eps-schedule-modal div.schedule-detail .reserve-capacity-container .percent-spinner button {
  display: block;
  width: 100%;
  margin: 4px 0;
}
#eps-schedule-modal div.schedule-detail .reserve-capacity-container .percent-spinner div.input {
  font-size: 150%;
  width: 90px;
}
#eps-schedule-modal div.schedule-detail .reserve-capacity-container .percent-spinner div.input input {
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
}

#eps-schedule-modal div.schedule-ribbon-container {
  position: relative;
  margin-left: 6em;
  margin-right: 6em;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  border-right: 1px solid rgba(0, 0, 0, 0.25);
}

#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon {
  height: 20px;
}

#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon .day-label {
  display: inline-block;
  width: 7.5em;
  text-align: left;
  vertical-align: 4px;
  opacity: 0.5;
  margin-left: -7.5em;
  font-size: 80%;
}
#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon .day-label.primary {
  color: #1279c6;
  font-weight: bold;
  opacity: 1;
  padding-top: 4px;
}

#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon div.ScheduleRibbonDay {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale {
  font-size: 80%;
  position: relative;
  height: 17px;
}
#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale span {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  color: rgba(0, 0, 0, 0.5);
}

#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale span.start {
  left: -14px;
}
#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale span.end {
  right: -14px;
}
#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale span.midday {
  width: 5em;
  text-align: center;
  margin-left: -2.5em;
  left: 50%;
}
#eps-schedule-modal div.schedule-ribbon-container div.schedule-ribbon-scale div.tick {
  position: absolute;
  width: 1px;
  height: 9px;
  top: 3px;
  border-left: 1px solid rgba(0, 0, 0, 0.25);
  z-index: 1;
}

#eps-schedule-modal div.schedule-ribbon-container button.schedule-ribbon-key-button {
  position: absolute;
  right: -4.5em;
  top: 50%;
  margin-top: -10px;
}

@media only screen and (max-width: 1000px) {
  #eps-schedule-modal div.schedule-detail div.column.state-container,
  #eps-schedule-modal div.schedule-detail div.column.spinner-container {
    width: 100%;
    padding: 1rem 1rem !important;
  }

  #eps-schedule-modal .day-buttons {
    padding-left: 0;
    padding-right: 0;
  }
  #eps-schedule-modal .day-buttons .button {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}
