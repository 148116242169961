.EPSScheduleRibbonDay {
  position: relative;
  display: block;
  height: 20px;
}

.EPSScheduleRibbonDay.empty-fill-eps-disabled {
  background: repeating-linear-gradient(
    45deg,
    rgba(180, 180, 180, 0.9),
    rgba(180, 180, 180, 0.9) 10px,
    rgba(180, 180, 180, 0.7) 10px,
    rgba(180, 180, 180, 0.7) 20px
  );
}

.EPSScheduleRibbonDay .now-cursor {
  position: absolute;
  display: inline-block;
  top: -2px;
  bottom: -2px;
  width: 2px;
  border-left: 2px solid red;
}

.EPSScheduleRibbonDay .item {
  position: absolute;
  display: inline-block;
  height: 100%;
  overflow: hidden;
}

.EPSScheduleRibbonDay .item.eps-enabled {
  background-color: #f56100;
}

.EPSScheduleRibbonDay .item.eps-disabled {
  background-color: rgb(180, 180, 180);
}
