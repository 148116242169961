.ScheduleRibbons {
}

.ScheduleRibbons > .schedule-ribbon-container {
  margin-left: 32px;
  margin-right: 28px;
}

.ScheduleRibbons > .schedule-ribbon-container {
  margin-left: 32px;
  margin-right: 28px;
}

.ScheduleRibbons > .schedule-ribbon-container small.schedule-type {
  display: block;
  font-weight: bold;
  padding-left: 0.2em;
}

.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container {
  font-size: 70%;
  text-align: center;
  margin: 20px 0;
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item {
  display: inline-block;
  padding-left: 0.25em;
  margin-right: 1em;
  margin-bottom: 3px;
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item > div.blip {
  display: inline-block;
  background-color: #000;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
  vertical-align: -4px;
  margin-right: 0.4em;
}

.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.force-charge > div.blip {
  background-color: rgb(19, 123, 73);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.only-charge > div.blip {
  background-color: rgb(128, 184, 35);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.normal > div.blip {
  background-color: rgb(80, 111, 161);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.only-discharge > div.blip {
  background-color: rgb(221, 224, 35);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.force-discharge > div.blip {
  background-color: rgb(96, 174, 222);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.disable > div.blip {
  background-color: rgb(154, 159, 165);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.dormant > div.blip {
  background-color: rgb(30, 30, 30);
}
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.ffr > div.blip,
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.ffr-low > div.blip,
.ScheduleRibbons > .schedule-ribbon-container > .schedule-ribbon-key-container div.item.ffr-high > div.blip {
  background-color: rgb(255, 188, 104);
}

@media only screen and (max-width: 767px) {
  .ScheduleRibbons > .schedule-ribbon-container {
    margin-left: 0;
    margin-right: 0;
  }
}
