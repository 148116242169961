#ErrorPage {
}
#ErrorPage .segment {
  margin-top: 20%;
}
#ErrorPage .segment .header {
  font-size: 400% !important;
}

#ErrorPage .segment p {
  font-size: 200% !important;
}
