/*******************************
        Site Overrides
*******************************/

.pv-dashboard-transparent-message {
  &.ui.positive.message,
  &.ui.negative.message,
  &.ui.info.message,
  &.ui.warning.message,
  &.ui.error.message,
  &.ui.success.message {
    background-color: fade(@positiveBackgroundColor, @pv-opacity);
    color: @pv-TextColor;
  }

  &.ui.positive.message,
  &.ui.negative.message,
  &.ui.info.message,
  &.ui.warning.message,
  &.ui.error.message,
  &.ui.success.message,
  &.ui.attached.positive.message,
  &.ui.attached.negative.message,
  &.ui.attached.info.message,
  &.ui.attached.warning.message,
  &.ui.attached.error.message,
  &.ui.attached.success.message {
    box-shadow: @pv-positiveBoxShadow;
  }

  &.ui.positive.message .header,
  &.ui.negative.message .header,
  &.ui.info.message .header,
  &.ui.warning.message .header,
  &.ui.error.message .header,
  &.ui.success.message .header {
    color: @pv-HeaderColor;
  }

  & a {
    text-decoration: underline;
    color: darken(#ffffff, 5%);
  }
  & a:hover {
    color: #ffffff;
  }
}
