#ForceChargeDischarge {
}

.no-select {
  user-select: none;
}

#ForceChargeDischarge div.title {
  display: inline-block;
  position: relative;
  padding-left: 2em;
}

#ForceChargeDischarge div.default-container {
  display: block;
  position: relative;
}

#ForceChargeDischarge div.default-container h4.header {
  display: inline-block;
}

#ForceChargeDischarge div.default-container div.beta-badge {
  position: absolute;
  right: -30px;
  top: -17px;
  opacity: 0.9;
}

/* TODO: re-address: */
#ForceChargeDischarge button {
  margin: 0.6em;
}

#ForceChargeDischarge div.edit-container div.edit-message {
  margin-top: -1em;
  display: inline-block;
  padding: 1em;
  cursor: pointer;
}
#ForceChargeDischarge div.edit-container div.edit-message span {
  color: #2185d0;
  padding-left: 0.15em;
}

#ForceChargeDischarge div.override-message > span {
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  padding: 1em 2em;
}
#ForceChargeDischarge div.override-message span span {
  padding-left: 0.15em;
}

#ForceChargeDischarge div.override-message button {
}

@media only screen and (max-width: 767px) {
  #ForceChargeDischarge {
    padding-top: 0.5rem;
  }

  #ForceChargeDischarge.hide-more {
    display: none;
  }
  .show-more #ForceChargeDischarge.hide-more {
    display: block;
  }
}
