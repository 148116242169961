#MainMenu {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 14px 14px 28px 0px #00000040;
  backdrop-filter: blur(1px);
  opacity: 0;
  padding-left: 1em;
  padding-right: 1em;
}

[device='mobile'] #MainMenu {
  width: 100%;
}

#MainMenu.visible {
  background-color: rgba(255, 255, 255, 0.95);
  opacity: 1;

  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#MainMenu div.top-controls {
}
#MainMenu div.top-buttons {
  margin-top: 1.5em;
}

#MainMenu .hero span.user {
  font-size: 16px;
}

#MainMenu div.middle-controls {
  margin-top: 1em;
}
#MainMenu div.middle-controls * {
  text-align: left;
}

#MainMenu div.middle-controls > .header {
  padding-top: 1rem;
}

#MainMenu div.middle-controls > .segment.basic:not(:last-child) {
  padding-bottom: 0;
}
#MainMenu div.middle-controls > .segment.basic:last-child {
  padding-bottom: 10rem;
}

#MainMenu div.top-controls .button {
  margin-top: 11px;
  margin-left: 0.5em;
}

#MainMenu div.top-controls .button.close-button {
  margin-left: 0;
}
