#weather {
  position: relative;
  text-align: center;
  padding: 0;
  margin: 2em auto 3em auto;
  width: 70%;
  max-width: 14em;
}
#weather.hidden {
  display: none;
}
#weather .update-postcode-button {
  text-transform: none !important;
  line-height: 20px;
}
#weather div.postcode {
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
}
#weather span.location-name {
  display: block;
  margin-bottom: 15px;
  opacity: 0.5;
}
#weather span.temperature {
  display: inline-block;
  font-size: 200%;
}
#weather span.temperature > sup {
  opacity: 0.5;
}
#weather img {
  display: inline-block;
  margin-left: 2em;
  margin-top: -15px;
}
#weather span.loading {
  display: block;
  margin: auto;
  opacity: 0.5;
}

@media only screen and (max-width: 767px) {
  #weather {
    margin: 1em auto 1em auto;
  }
  #weather .hide-more {
    display: none;
  }
  .show-more #weather .hide-more {
    display: block;
  }
  #weather .update-postcode-button {
    line-height: 1.4rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
}
