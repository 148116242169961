/* LESS resides in ../../semantic-ui/powervault-v1/globals/site.overrides */

#live-view {
  position: relative;
}

#live-view span.clusters-desc {
  display: inline-block;
  opacity: 0.8;
}

#live-view > .soc {
  display: inline-block;
  position: absolute;
  top: 1rem;
  right: 1.2em;
  vertical-align: middle;
}
#live-view > .soc > span {
  vertical-align: middle;
  padding-right: 0.6em;
  font-weight: bold;
}
#live-view > .soc > .battery-icon {
}

#live-view div.live-view-container {
  position: relative;
  margin: auto;
  margin-top: 3rem;
  max-width: 400px;
  min-height: 320px;
  box-sizing: border-box;
  padding-top: 15px;
  margin-bottom: 2em;
}
#live-view div.live-view-container div.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
}

#live-view div.live-view-container > div.container {
  position: relative;
  min-height: 320px;
  width: 100%;
  text-align: center;
}

#live-view div.live-view-container > div.container > div.aux-node-container {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-top: 25em;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0) 100%);
}

#live-view div.live-view-container > div.container div.node {
  position: absolute;
  border-radius: 100%;
  background-color: #f2f2f2;
  border: 1px solid #e8e8e8;
  width: 100px;
  height: 100px;
  text-align: center;
  transition: border-color 1s;
  transition: box-shadow 1s;
  font-size: 110%;
}
#live-view div.live-view-container > div.container div.node.aux {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  transform: scale(0.8);
  vertical-align: top;
}

#live-view div.live-view-container > div.container > div.node.grid {
  left: 7px;
  top: 0px;
}
#live-view div.live-view-container > div.container > div.node.grid > img.grid-icon {
  width: 3.3em;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: -6px;
}
#live-view div.live-view-container > div.container > div.node.grid.empty > img.grid-icon {
  margin-top: 22px;
}

#live-view div.live-view-container > div.container > div.node.solar {
  right: 7px;
  top: 0px;
}
#live-view div.live-view-container > div.container > div.node.solar > img.solar-icon {
  width: 2.8em;
  margin-top: 14px;
  margin-left: 1px;
  margin-bottom: -3px;
}
#live-view div.live-view-container > div.container > div.node.solar.empty > img.solar-icon {
  margin-top: 28px;
}

#live-view div.live-view-container > div.container > div.node.battery {
  left: 7px;
  top: 220px;
}
#live-view div.live-view-container > div.container > div.node.battery > .battery-icon {
  width: 37px;
}

#live-view div.live-view-container > div.container > div.node.home {
  right: 7px;
  top: 220px;
}

#live-view div.live-view-container > div.container div.node.aux > img.aux-icon {
  width: 3.3em;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: -6px;
}
#live-view div.live-view-container > div.container div.node.aux.empty > img.aux-icon {
  margin-top: 22px;
}

#live-view div.live-view-container.stale-data > div.container div.node {
  border-color: #666;
  box-shadow: 0 0 15px 2px #666;
}
#live-view div.live-view-container > div.container div.node.joint {
  width: 70px;
  height: 70px;
  background-color: #ddd;
  border-color: #aaa;
  left: 50%;
  top: 160px;
  margin-left: -35px;
  margin-top: -35px;
}

#live-view div.live-view-container > div.container div.node > i.icon {
  margin: 23px 0 0 0;
  transition: margin 0.5s;
}
#live-view div.live-view-container > div.container div.node.empty {
  border-color: #666;
  box-shadow: none;
}
#live-view div.live-view-container > div.container div.node.disconnected {
  opacity: 0.5;
  border-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
#live-view div.live-view-container > div.container div.node.gateway-eps-mode {
  opacity: 0.5;
  border-color: rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.2);
  box-shadow: none;
}
#live-view div.live-view-container > div.container div.node.empty > i.icon {
  margin: 33px 0 0 0;
}
#live-view div.live-view-container > div.container div.node > span {
  display: block;
  margin-top: 5px;
  transition: opacity 0.5s;
}
#live-view div.live-view-container > div.container div.node.empty > span {
  opacity: 0;
}

#live-view div.live-view-container > div.svg-container {
  position: absolute;
  left: 50%;
  margin-left: -200px;
  overflow: hidden;
  width: 400px;
  /* height: 320px; */
  height: 500px;
}

#live-view path {
  fill: none;
  opacity: 0.9;
  transition: stroke-width 1s;
}

#live-view path.flow {
  stroke: #fff;
  /* opacity: 0.8; */
  animation-iteration-count: infinite;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-name: flow;
  stroke-dasharray: 1.6;
}
#live-view path.flow.out {
  animation-direction: reverse;
}
#live-view path.flow.in {
  animation-direction: normal;
}

#live-view .show-more-button a {
  position: absolute;
  text-align: 'right';
  display: block;
  bottom: 0.5em;
  right: 1em;
}
#live-view .show-more-button a:link,
#live-view .show-more-button a:visited,
#live-view .show-more-button a:active {
  text-decoration: none !important;
}

@keyframes flow {
  to {
    stroke-dashoffset: 10;
  }
}

@media only screen and (max-width: 767px) {
  #live-view span.clusters-desc {
    font-size: 70% !important;
  }
}
