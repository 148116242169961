.GatewaySettings {
}

.GatewaySettings .accordion {
  padding-left: 1em !important;
}

.GatewaySettings .accordion .title {
  color: #009d9c !important;
}

.GatewaySettings .accordion .content {
  padding-left: 1.5em !important;
  margin-bottom: 1em;
}

.GatewaySettings div.appliances {
  margin-left: 1em;
  margin-top: 2em;
  margin-bottom: 2.5em;
}

.GatewaySettings div.appliances > div {
  text-align: center;
  display: inline-block;
  margin: 0 1em;
}

.GatewaySettings div.appliances > div > small {
  display: block;
}

.GatewaySettings img.ui.custom-icon {
  margin: auto;
  width: 4em;
  border-width: 3px !important;
  padding: 7px;
}
