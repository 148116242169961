#Footer {
  background: #101010;
  /* height: 95px; */
  padding: 2rem 20px 2rem 20px;
  text-align: center;
  color: #ffffff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

#Footer div.copyright,
#Footer div.version {
  display: inline-block;
}

#Footer a {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

#Footer div.copyright {
  margin-top: 5px;
  display: block;
  opacity: 0.5;
}

[device='mobile'] #Footer .column {
  text-align: center;
}

#release-notes-modal span.date {
  opacity: 0.5;
  padding-left: 10px;
  font-size: 80%;
}

@media only screen and (max-width: 640px) {
  #Footer {
  }
}
