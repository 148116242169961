.App {
  position: relative;
  background: #101010;
  overflow: hidden;
}

/* workaround to display loaders inside a modal */
.ui.dimmer .ui.workaround.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}
.ui.dimmer .ui.workaround.loader:after {
  border-color: #767676 transparent transparent;
}

span.span-link {
  color: #009d9c;
  font-weight: bold;
  cursor: pointer;
}
span.span-link:hover {
  color: black;
  font-weight: bold;
}

.card:hover {
  text-decoration: none !important;
}

.ui.modal > .header {
  background-color: #009d9c;
  color: white;
}

@media only screen and (min-width: 767px) {
  [class*='mobile-only'] {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  [class*='mobile-hidden'] {
    display: none !important;
  }
}
