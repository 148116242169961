#charts {
}

#charts div.container {
  position: relative;
}

#charts div.container.operating-state {
  padding-top: 1rem;
}

#charts .react-datepicker__input-container {
  display: none;
}
#charts .react-datepicker__portal {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}

#charts span.clusters-desc {
  display: inline-block;
  opacity: 0.8;
}

#charts span.date {
  /* padding-left: 10px; */
  opacity: 0.5;
  font-size: 80%;
}

#charts div.date-selector {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 15px;
}

#charts div.date-selector button.today-button,
#charts div.date-selector button.date-picker-button {
  margin-right: 1.2em;
}

#charts div.date-selector div.date-picker {
}

#charts div.date-selector div.date-picker div.react-date-picker__wrapper {
  display: none;
}
#charts div.date-selector div.date-picker div.date-picker-calendar {
  margin-left: -80px;
  margin-top: -28px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
}

#charts div.date-selector div.date-picker div.date-picker-calendar div.react-calendar__month-view__weekdays__weekday * {
  text-decoration: none;
}
#charts div.date-selector div.date-picker div.date-picker-calendar button.react-calendar__month-view__days__day--weekend {
  color: #006edc;
}
#charts div.date-selector div.date-picker div.date-picker-calendar button.react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
  color: #fff;
}

#charts button.fullscreen {
  position: absolute;
  top: 0;
  right: 1em;
}
/* 
#charts .ribbon-container {
  margin-top: 20px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
} */

@media only screen and (max-width: 640px) {
  #charts div.date-selector {
    position: relative;
    text-align: right;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  #charts span.clusters-desc {
    font-size: 70% !important;
  }
}
