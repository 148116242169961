#smart-schedule-modal {
  margin-top: 0;
}

#smart-schedule-modal div.status {
  float: left;
  font-size: 1.3em;
  padding-top: 7px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}
#smart-schedule-modal div.status > span {
  font-weight: bold;
  color: #000000;
}

@media only screen and (max-width: 1000px) {
}
