.InitialLoader {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  text-align: center;
}
.InitialLoader.active {
  display: block;
}
.InitialLoader .container {
  position: absolute;
  width: 100%;
  top: 40%;
}
.InitialLoader .container img {
  width: 400px;
}
.InitialLoader .container span.sub-header {
  display: block;
  margin: auto;
  margin-top: -2px;
  width: 235px;
  font-weight: bold;
  text-align: right;
  color: rgb(75, 195, 233);
}
.InitialLoader .container div.loader-container {
  margin: auto;
  margin-top: 10px;
  width: 238px;
  text-align: left;
}
.InitialLoader .container div.loader-container small.loading-text {
  margin-left: 0.7em;
  vertical-align: middle;
}
.InitialLoader .container div.loader-container small.loading-text.orange {
  color: rgb(206, 134, 0);
}
.InitialLoader .container div.loader-container small.loading-text.red {
  color: red;
}
.InitialLoader .container div.failed-to-load {
  margin: auto;
  margin-top: 30px;
  width: 350px;
}

.fade-in {
  animation-name: fadeIn;
  animation-duration: 5s;
}
.fade-in-delay {
  animation-name: fadeIn;
  animation-duration: 6s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
