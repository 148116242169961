#led-settings-modal {
  margin-top: 0;
}
#led-settings-modal div.option {
  cursor: pointer;
}
#led-settings-modal div.option > .icon {
  opacity: 0;
  float: right;
  color: #2185d0 !important;
}
#led-settings-modal div.option:hover {
  opacity: 0.5;
}
#led-settings-modal div.option.active {
  border-top: 2px solid #2185d0 !important;
}
#led-settings-modal div.option.active > .icon {
  opacity: 1;
}

@media only screen and (max-width: 1000px) {
}
