#chart {
  margin-left: -29px;
  margin-right: 28px;
}
#chart div.tooltip {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
}
#chart div.tooltip > strong {
  display: block;
  text-align: center;
  margin-bottom: 5px;
}
#chart div.tooltip > span {
  display: block;
}

#chart div.tooltip > span.missingData {
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 0 5px;
}

#chart div.tooltip > span.noInternet {
  margin-top: 10px;
  background-color: rgb(160, 160, 160);
  color: white;
  padding: 0 5px;
}

#chart div.tooltip > span.gridClampDisconnected {
  margin-top: 10px;
  background-color: rgb(255, 122, 134);
  color: white;
  padding: 0 5px;
}

#chart div.tooltip > span.hardwareId {
  display: block;
  text-align: right;
  font-size: 50%;
  padding-top: 5px;
  padding-right: 4px;
  color: black;
  opacity: 0.3;
}

#chart div.tooltip > span.hardwareIdChanged {
  margin-top: 10px;
  background-color: rgb(47, 177, 21);
  color: white;
  padding: 0 5px;
}

#chart div.tooltip > span span.label {
  padding-right: 5px;
}

#chart div.tooltip > span span.value {
  padding-right: 5px;
}

#chart div.tooltip > span span.unit {
  opacity: 0.5;
}

#chart .legend {
  position: relative;
  text-align: center;
}

#chart .legend .legend-item {
  display: inline-block;
  padding: 0 0.5em 0 0.5em;
}

#chart .legend .legend-item.Solar {
  color: red;
}

#chart .recharts-surface {
  overflow: visible;
}

@media only screen and (max-width: 767px) {
  #chart .legend {
    margin-left: 40px;
  }
}
