#SendToMobile {
}

#SendToMobile .desktop-popup {
  text-align: center;
  position: fixed;
  top: 1.5em;
  right: 1.5em;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  box-shadow: 14px 14px 28px 0px #00000040;
  backdrop-filter: blur(10px);
  padding: 20px;
}

#SendToMobile .desktop-popup img {
  margin: auto;
  width: 200px;
  height: 200px;
  display: inline-block;
  margin: 25px 0 40px 0;
}

#SendToMobile .mobile-popup {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  box-shadow: 14px 14px 28px 0px #00000040;
  backdrop-filter: blur(10px);
  padding: 20px;
}

#SendToMobile ol {
  padding: 10px 30px;
}

#SendToMobile ol li {
  padding: 5px;
}
