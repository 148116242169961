#unit-selector div.segment {
  height: 400px;
}

#unit-selector-modal.ui.modal > .content {
  padding-top: 0;
}
#unit-selector-modal.ui.modal > .content.search-area {
  padding-top: 1.5rem;
}
#unit-selector-modal.ui.modal > .content.search-area div.staff-filters {
  text-align: right;
  padding-bottom: 1em;
}
#unit-selector-modal.ui.modal > .content.search-area div.staff-filters > small {
  display: block;
  margin-bottom: 0.5rem;
  opacity: 0.6;
}

#unit-selector-modal.ui.modal > .content.search-area div.staff-filters .button {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}

#unit-selector-modal.ui.modal > .staff-ui-button {
  display: inline-block;
  position: absolute;
  right: 1.7em;
  top: 1.2rem;
}
#unit-selector-modal.ui.modal div.scrolling {
  padding-top: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(80vh - 15em);
}

#unit-selector-modal.ui.modal div.page-buttons {
  text-align: left;
}
#unit-selector-modal.ui.modal div.page-buttons span {
  font-weight: bold;
  padding: 0 1em;
}
#unit-selector-modal.ui.modal div.select-buttons {
  position: absolute;
  text-align: right;
  bottom: 0;
  right: 0;
  padding: 1rem;
}

#unit-selector-modal.ui.modal div.select-buttons .button {
  margin-left: 0.5em;
}

#unit-selector-modal .card {
  cursor: pointer;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
#unit-selector-modal .card.unit-cluster .content .header,
#unit-selector-modal .card.unit-cluster .content .description {
  color: #ffffff !important;
}
#unit-selector-modal .card.disabled,
#unit-selector-modal .card.disabled .checkbox {
  cursor: not-allowed;
  opacity: 0.5;
}

#unit-selector-modal .card div.unit-history {
  text-align: right;
  opacity: 0.5;
}
#unit-selector-modal .card span.quote {
  opacity: 0.6;
  padding-left: 0.1em;
  padding-right: 0.1em;
}

#unit-selector-modal .card-separator {
  margin-top: 1em;
  margin-bottom: 1.5em;
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #22242626;
}

#unit-selector-modal .card.single-unit div.content {
  padding-top: 30px;
}
#unit-selector-modal .card.unit-cluster div.content {
  padding-top: 24px;
}

#unit-selector-modal .card.single-unit:hover div.content,
#unit-selector-modal .card.single-unit.selected div.content {
  background-color: #eee;
}

#unit-selector-modal .card .checkbox {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  top: 27px;
  right: 1em;
  cursor: pointer;
}

#unit-selector-modal .card div.relationship-label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 5px;
  opacity: 0.5;
  color: #fff;
  font-size: 80%;
}
#unit-selector-modal .card div.relationship-label.owner {
  background-color: green;
}
#unit-selector-modal .card div.relationship-label.staff-visible {
  background-color: red;
}
#unit-selector-modal .card div.relationship-label.installer {
  background-color: purple;
}
#unit-selector-modal .card div.relationship-label.group-member {
  background-color: blue;
}

#unit-selector-modal .card div.group-label {
  position: absolute;
  right: 0;
  top: 0;
  text-align: right;
  padding: 2px 5px;
  background-color: rgba(1, 1, 1, 0.15);
  color: #000;
  font-size: 80%;
}

@media only screen and (max-width: 1000px) {
  #unit-selector-modal.ui.modal {
    margin-top: 0;
  }
  #unit-selector-modal.ui.modal > .actions {
    padding-bottom: 5rem !important;
  }
  #unit-selector-modal.ui.modal.unit-selected > .actions {
    padding-bottom: 7.5rem !important;
  }
  #unit-selector-modal.ui.modal div.select-buttons .button {
    margin-bottom: 0.5rem;
  }
  #unit-selector-modal.ui.modal > .content {
    padding-top: 0.3rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #unit-selector-modal.ui.modal > .content.search-area {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }
  #unit-selector-modal.ui.modal > .content.search-area input {
    margin-top: 0.3rem;
  }
  #unit-selector-modal.ui.modal > .content.search-area div.staff-filters {
    padding-bottom: 0;
  }
  #unit-selector-modal.ui.modal > .staff-ui-button {
    right: 0.7rem;
    top: 0.6rem;
  }

  #unit-selector-modal .card {
    width: 100%;
    font-size: 0.9em;
    margin-top: 0.4rem;
    margin-bottom: 0.2rem;
  }
  #unit-selector-modal .card .content {
    padding-top: 0;
    padding-bottom: 1rem;
  }
}
