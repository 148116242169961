#schedule-modal {
  margin-top: 0;
}

/* Modal Header */

#schedule-modal div.header h2 {
  margin: 0;
}

/* Day Buttons */
#schedule-modal .day-buttons {
}
#schedule-modal .day-buttons button.today span {
  text-decoration: underline;
}

/* Left Buttons */
#schedule-modal div.left-buttons {
  float: left;
}

#schedule-modal div.left-buttons .button {
  margin-left: 1em;
}

/* Schedule Items */

#schedule-modal div.schedule-slot {
  position: relative;
  height: 60px;
  cursor: pointer;
  border-left-width: 22px !important;
  border-left-style: solid !important;
}
#schedule-modal div.schedule-slot.inactive > * {
  opacity: 0.5;
}
#schedule-modal div.schedule-slot.active {
  color: #2185d0;
  background-color: #eee;
}
#schedule-modal div.schedule-slot.conflict {
  color: #9f3a38;
  background-color: #fff6f6;
}
#schedule-modal div.schedule-slot.new-button {
  border: 0;
  border-left-color: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: bold;
  background-color: rgb(224, 225, 226);
  color: rgba(0, 0, 0, 0.6);
}
#schedule-modal div.schedule-slot.new-button.primary {
  color: #ffffff;
}
#schedule-modal div.schedule-slot.new-button > div {
  padding-top: 6px;
}
#schedule-modal .color.force-charge {
  border-left-color: rgb(19, 123, 73) !important;
}
#schedule-modal .color.only-charge {
  border-left-color: rgb(128, 184, 35) !important;
}
#schedule-modal .color.normal {
  border-left-color: rgb(80, 111, 161) !important;
}
#schedule-modal .color.only-discharge {
  border-left-color: rgb(221, 224, 35) !important;
}
#schedule-modal .color.force-discharge {
  border-left-color: rgb(96, 174, 222) !important;
}
#schedule-modal .color.disable {
  border-left-color: rgb(154, 159, 165) !important;
}
#schedule-modal .color.dormant {
  border-left-color: rgb(0, 0, 0) !important;
}
#schedule-modal .color.ffr,
#schedule-modal .color.ffr-low,
#schedule-modal .color.ffr-high {
  border-left-color: rgb(255, 188, 104) !important;
}

#schedule-modal div.schedule-slot div.schedule {
  position: absolute;
  left: 30px;
  top: 0;
  height: 100%;
}
#schedule-modal div.schedule-slot div.schedule h2.time {
  color: inherit;
  margin: 15px 0 3px 0;
}

#schedule-modal div.schedule-slot div.state {
  position: absolute;
  left: 110px;
  top: 0;
  height: 100%;
  padding: 0 10px;
  text-align: center;
  display: table;
}
#schedule-modal div.schedule-slot div.state > span {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
}

#schedule-modal div.schedule-slot button.negative {
  margin-top: -2px;
}

/* Schedule Detail */

#schedule-modal div.schedule-detail {
  vertical-align: middle;
}
#schedule-modal div.schedule-detail.error {
  background-color: #fff6f6;
  border-color: #e0b4b4;
}
#schedule-modal div.schedule-detail.error .header {
  color: #9f3a38 !important;
}

#schedule-modal div.schedule-detail.no-selection div.header,
#schedule-modal div.schedule-detail.item-removed div.header,
#schedule-modal div.schedule-detail.saving div.header {
  opacity: 0.5;
}

#schedule-modal div.schedule-detail h2 {
  display: inline;
  margin-right: 10px;
}

#schedule-modal div.schedule-detail .state-container h2 {
  display: block;
  margin-bottom: 10px;
}

#schedule-modal div.schedule-detail .state-container div.dropdown {
  display: inline-block;
  font-size: 150%;
  width: 12em;
  min-width: 12em;
  border-left-width: 11px;
  border-left-style: solid;
}
#schedule-modal div.schedule-detail .state-container div.dropdown .item {
  padding: 14px !important;
  border-left-width: 11px;
  border-left-style: solid;
}

#schedule-modal div.schedule-detail .spinner-container h2 {
  display: inline-block;
  margin-bottom: 43px;
}

#schedule-modal div.schedule-detail .spinner-container .time-spinner {
  display: inline-block;
  margin-right: 10px;
}

#schedule-modal div.schedule-detail .spinner-container .time-spinner button {
  display: block;
  width: 100%;
  margin: 4px 0;
}
#schedule-modal div.schedule-detail .spinner-container .time-spinner div.input {
  font-size: 150%;
  width: 70px;
}

#schedule-modal div.schedule-ribbon-container {
  margin-top: 1em;
}

#schedule-ribbon-key-container {
  margin-top: 15px;
  font-size: 80%;
  text-align: center;
}

#schedule-ribbon-key-container button.info-button {
  margin-top: 15px;
}

#schedule-ribbon-key-container div.item {
  display: inline-block;
  border-left: 1em solid #000;
  padding-left: 0.25em;
  margin-right: 1em;
  margin-bottom: 3px;
}

#schedule-ribbon-key-container div.item.force-charge {
  border-left-color: rgb(19, 123, 73);
}
#schedule-ribbon-key-container div.item.only-charge {
  border-left-color: rgb(128, 184, 35);
}
#schedule-ribbon-key-container div.item.normal {
  border-left-color: rgb(80, 111, 161);
}
#schedule-ribbon-key-container div.item.only-discharge {
  border-left-color: rgb(221, 224, 35);
}
#schedule-ribbon-key-container div.item.force-discharge {
  border-left-color: rgb(96, 174, 222);
}
#schedule-ribbon-key-container div.item.disable {
  border-left-color: rgb(154, 159, 165);
}
#schedule-ribbon-key-container div.item.dormant {
  border-left-color: rgb(30, 30, 30);
}
#schedule-ribbon-key-container div.item.ffr,
#schedule-ribbon-key-container div.item.ffr-low,
#schedule-ribbon-key-container div.item.ffr-high {
  border-left-color: rgb(255, 188, 104);
}

@media only screen and (max-width: 1000px) {
  #schedule-modal div.schedule-detail div.column.state-container,
  #schedule-modal div.schedule-detail div.column.spinner-container {
    width: 100%;
    padding: 1rem 1rem !important;
  }

  #schedule-modal .day-buttons {
    padding-left: 0;
    padding-right: 0;
  }
  #schedule-modal .day-buttons .button {
    padding-left: 0.3em;
    padding-right: 0.3em;
  }

  #schedule-modal div.left-buttons .button {
    margin-bottom: 1rem;
  }
}
