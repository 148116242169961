#InstallDetails .list > .item > .content > span {
  display: block;
  margin-bottom: 5px;
}

#InstallDetails span.clusters-desc {
  display: inline-block;
  opacity: 0.8;
}

#InstallDetails .custom-name {
  padding-bottom: 1rem;
}
#InstallDetails .custom-name.edit-mode {
  padding-bottom: 4rem;
  height: 3rem;
  vertical-align: middle;
}

#InstallDetails .unit-id,
#InstallDetails .firmware,
#InstallDetails .model,
#InstallDetails .contract-id,
#InstallDetails .inverter-country-code,
#InstallDetails .hotspot-key,
#InstallDetails .warranty-activation {
  opacity: 0.5;
  font-style: italic;
}

#InstallDetails .hotspot-key-secret {
  font-family: monospace;
  font-size: 120%;
  font-weight: bold !important;
  cursor: pointer;
}
#InstallDetails .hotspot-key-secret.reveal {
  color: red;
}

#InstallDetails .wifi-strength-icon {
  position: absolute;
  top: 0;
  right: 0;
}
