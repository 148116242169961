/*******************************
         Site Overrides
*******************************/

/********
 Anchors
********/
a,
a:link,
a:visited,
a:active {
  color: @primaryColor;
}
a:hover {
  color: #000000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

a.inverted,
a.inverted:link,
a.inverted:visited,
a.inverted:active {
  color: #ffffff;
}
a.inverted:hover {
  color: @primaryColor;
  text-decoration: none;
}


/**********************
 Semantic Menu Buttons
**********************/

#daily div.date-range-buttons .buttons button.active {
  color: @primaryColor !important;
}


/************
 Main Colors
************/

.pv-primary-color,
.pv-positive-color {
  color: @primaryColor;
}
.pv-secondary-color,
.pv-negative-color {
  color: @secondaryColor;
}

.pv-red-color {
  color: @red;
}
.pv-yellow-color {
  color: @yellow;
}
.pv-blue-color {
  color: @blue;
}
.pv-green-color {
  color: @green;
}

.pv-primary-background-color,
.pv-positive-background-color {
  background-color: @primaryColor !important;
}
.pv-secondary-background-color,
.pv-negative-background-color {
  background-color: @secondaryColor !important;
}
.pv-red-background-color {
  background-color: @red !important;
}
.pv-yellow-background-color {
  background-color: @yellow !important;
}
.pv-blue-background-color {
  background-color: @blue !important;
}
.pv-green-background-color {
  background-color: @green !important;
}


/*****************
 Live View Colors
*****************/

.pv-grid-stroke {
  stroke: @red;
}
.pv-solar-stroke {
  stroke: @yellow;
}
.pv-battery-stroke {
  stroke: @blue;
}
.pv-home-stroke {
  stroke: @green;
}
.pv-aux-stroke {
  stroke: grey;
}

.pv-grid-node {
  border-color: @red;
  box-shadow: 0 0 10px 5px @red;
}
.pv-solar-node {
  border-color: @yellow;
  box-shadow: 0 0 10px 5px @yellow;
}
.pv-battery-node {
  border-color: @blue;
  box-shadow: 0 0 10px 5px @blue;
}
.pv-home-node {
  border-color: @green;
  box-shadow: 0 0 10px 5px @green;
}
.pv-aux-node {
  border-color: grey;
  box-shadow: 0 0 10px 5px grey;
}
