.ScheduleRibbonDay {
}
.ScheduleRibbonDay.clickable {
  cursor: pointer;
}

.ScheduleRibbonDay div.day-name {
  float: left;
  width: 2em;
  text-align: left;
  font-family: monospace, monospace;
  overflow: hidden;
  opacity: 0.6;
}
.ScheduleRibbonDay.highlight div.day-name {
  opacity: 1;
  font-weight: bold;
}
.ScheduleRibbonDay div.day-name.today {
  text-decoration: underline;
}
.ScheduleRibbonDay.dim div.day-name {
  opacity: 0.4;
}

.ScheduleRibbonDay div.ribbon-container {
  position: relative;
  height: 20px;
  margin-bottom: 1px;
}
.ScheduleRibbonDay div.ribbon-container.show-day-name {
  margin-left: 2em;
}

.ScheduleRibbonDay div.ribbon-container.empty-fill-normal {
  background: repeating-linear-gradient(45deg, rgba(80, 111, 161, 1), rgba(80, 111, 161, 1) 10px, rgba(80, 111, 161, 0.9) 10px, rgba(80, 111, 161, 0.9) 20px);
}
.ScheduleRibbonDay.dim div.ribbon-container.empty-fill-normal {
  background: repeating-linear-gradient(
    45deg,
    rgba(80, 111, 161, 0.18),
    rgba(80, 111, 161, 0.18) 10px,
    rgba(80, 111, 161, 0.15) 10px,
    rgba(80, 111, 161, 0.15) 20px
  );
}

.ScheduleRibbonDay div.ribbon-container .now-cursor {
  position: absolute;
  display: inline-block;
  top: -2px;
  bottom: -2px;
  width: 2px;
  border-left: 1px dashed black;
  opacity: 1 !important;
}

.ScheduleRibbonDay div.ribbon-container .item {
  position: absolute;
  display: inline-block;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  border-right: 1px solid rgba(255, 255, 255, 1);
}
.ScheduleRibbonDay.dim div.ribbon-container .item {
  opacity: 0.25;
}

.ScheduleRibbonDay div.ribbon-container .item:hover {
  opacity: 0.8;
}
.ScheduleRibbonDay.dim div.ribbon-container .item:hover {
  opacity: 0.2;
}

.ScheduleRibbonDay div.ribbon-container .item.force-charge {
  background-color: rgb(19, 123, 73);
}
.ScheduleRibbonDay div.ribbon-container .item.only-charge {
  background-color: rgb(128, 184, 35);
}
.ScheduleRibbonDay div.ribbon-container .item.normal {
  background-color: rgb(80, 111, 161);
}
.ScheduleRibbonDay div.ribbon-container .item.only-discharge {
  background-color: rgb(221, 224, 35);
  color: #656710;
}
.ScheduleRibbonDay div.ribbon-container .item.force-discharge {
  background-color: rgb(96, 174, 222);
}
.ScheduleRibbonDay div.ribbon-container .item.disable {
  background-color: rgb(154, 159, 165);
}
.ScheduleRibbonDay div.ribbon-container .item.dormant {
  background-color: rgb(30, 30, 30);
}
.ScheduleRibbonDay div.ribbon-container .item.grid-services {
  background-color: rgb(255, 188, 104);
}
.ScheduleRibbonDay div.ribbon-container .item.eps-enabled {
  background-color: #f56100;
}
.ScheduleRibbonDay div.ribbon-container .item.eps-disabled {
  background-color: rgb(180, 180, 180);
}
