#Preferences {
}

#Preferences div.Preferences.content > div.segments div.segment:hover {
  cursor: pointer;
  background-color: rgba(0, 157, 156, 0.1);
}

#Preferences div.Preferences.content > div.segments div.segment i.icon {
  position: absolute;
  top: 50%;
  right: 0.5em;
  margin-top: -0.5em;
  opacity: 0.4;
}

@media only screen and (max-width: 767px) {
  #Preferences #toastPreferences > div {
    margin-top: -1.5em;
    /* padding-left: 0.2em; */
    /* padding-right: 0.2em; */
  }
}
