.ScheduleRibbonScale {
  position: relative;
  margin: 0;
  padding: 0;
  height: 20px;
  font-size: 1rem;
}

.ScheduleRibbonScale .tick {
  position: absolute;
  display: inline-block;
  text-align: center;
  padding-top: 2px;
  color: rgba(0, 0, 0, 0.7);
}

.ScheduleRibbonScale .tick > div {
  position: absolute;
  background-color: black;
  left: 50%;
  top: 0;
  width: 1px;
  height: 5px;
}
