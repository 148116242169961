.TimeRemaining {
  display: block;
  margin-top: 3em;
  border-radius: 1em;
  padding: 1em;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.TimeRemaining .numbers {
  display: block;
  text-align: center;
}

.TimeRemaining .numbers .number-container {
  display: inline-block;
  /* background-color: blue; */
  margin: 0 0.5em;
}

.TimeRemaining .numbers .number-container .number {
  width: 1.5em;
  height: 1.5em;
  font-size: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TimeRemaining .numbers .number-container .label {
  display: inline-block;
}
