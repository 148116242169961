.NavBar {
  position: relative;
  background: #101010;
  height: 70px;
  padding: 17px 20px 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.NavBar .button.help-button {
  margin-right: 1em !important;
}

.NavBar .ui.button.main-menu-button {
  text-transform: lowercase !important;
}

/* Make SELECT button narrow below 767px */
@media only screen and (max-width: 767px) {
  .NavBar {
    padding-left: 0.8em !important;
    padding-right: 0.8em !important;
  }
  .NavBar .select-unit-button {
    padding-left: 0.8em !important;
    padding-right: 0.8em !important;
  }
}

/* Minimise main-menu-button below 1000px */
@media only screen and (max-width: 1000px) {
  .NavBar .select-unit-button span,
  .NavBar .main-menu-button span {
    display: none !important;
  }
}
