#Help .content {
  padding-right: 3em;
}

#Help p {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

#Help ul {
  padding-left: 2em !important;
}

#Help ul li {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
