#home {
  min-height: 100vh;
}

#home div.dashboard {
  margin-top: 0px;
}

#home div.global-messages > div {
  border-bottom: 1px solid #000;
  text-align: center;
}
#home div.global-messages > div > .close.icon {
  top: 1em;
  right: 1em;
}

#home div.selected-units-bar {
  padding-top: 1.3em;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff !important;
  box-shadow: 14px 14px 28px 0px #00000040;
  backdrop-filter: blur(5px);
}

#home div.selected-units-bar .card {
  width: auto;
  padding-right: 2.5em;
  margin-top: 0;
  margin-bottom: 0;
  background: none;
  border: 0;
  outline: 0;
  box-shadow: none;
}
#home div.selected-units-bar .card.cluster {
  /* background-color: rgba(0, 157, 156, 0.8) !important; */
  background: none !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
}
#home div.selected-units-bar .card.cluster .content {
  padding-bottom: 8px;
  padding-right: 3em;
}
#home div.selected-units-bar .content .header,
#home div.selected-units-bar .content .description,
#home div.selected-units-bar .content .description small {
  color: #ffffff !important;
  opacity: 0.9;
}
#home div.selected-units-bar .content .header small {
  opacity: 0.7 !important;
}

#home div.pushable,
#home div.pusher {
  min-height: 100vh;
}

#home div.message.pv-dashboard-transparent-message {
  margin-top: 1rem;
}

#home #charts.segment,
#home #live-view.segment,
#home #daily.segment,
#home #settings.segment {
  /* background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 14px 14px 28px 0px #00000040; */
}

#home .ui.stretched.grid > .row > .column,
.ui.stretched.grid > .column,
.ui.grid > .stretched.row > .column,
.ui.grid > .stretched.column:not(.row),
.ui.grid > .row > .stretched.column.stretch-height {
  align-self: stretch !important;
}

#home .ui.stretched.grid > .row.collapse-if-empty:empty,
#home .ui.stretched.grid > .row.collapse-if-empty:has(.column.no-padding-when-stacked:empty) {
  display: none !important;
}

#home .ui.stretched.grid > .row > .column.no-padding-when-stacked {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
  #home div.selected-units-bar .card {
    margin-left: 1.5em;
    margin-right: 1.5em;
    width: 100%;
    font-size: 80%;
  }
}
