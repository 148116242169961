#daily {
  min-height: 20rem;
}

#daily .solar-icon {
  /* color: #fbb03b; */
  width: 2em;
  vertical-align: -8px;
  padding: 0;
  margin: 0;
}
#daily .grid-icon {
  /* color: #c1272d; */
  width: 2em;
  vertical-align: -8px;
  padding: 0;
  margin: 0;
}

#daily div.date-range-buttons {
  text-align: center;
  margin-bottom: 4ex;
}
#daily div.date-range-buttons .buttons {
  margin-bottom: 10px;
}
#daily div.date-range-buttons .buttons button {
  padding-left: 1em !important;
  padding-right: 1em !important;
}
#daily div.date-range-buttons .buttons button.active {
  /* color: #2185d0 !important; */
  font-weight: bold;
}
#daily div.date-range-buttons .menu .item {
  padding: 1em 1.5em !important;
}
#daily div.date-range-buttons .menu .item.selected {
  /* color: #2185d0 !important; */
}

#daily div.container {
  text-align: center;
}

#daily span.clusters-desc {
  display: inline-block;
  opacity: 0.8;
}

#daily div.container i.icon {
  margin: 0;
}
#daily div.container .statistic {
  margin-top: 0;
  margin-bottom: 30px;
}

#daily div.container .statistic span {
  color: rgba(0, 0, 0, 0.5);
  text-transform: capitalize;
  font-size: 50%;
  display: block;
}

#daily div.container .statistic .label {
  text-transform: none;
  color: rgba(0, 0, 0, 0.5);
}

#daily div.container .aux-icon {
  width: 2em;
  vertical-align: -9px;
}

@media only screen and (max-width: 767px) {
  #daily div.date-range-buttons {
    margin-top: 1.5rem;
  }
  #daily div.date-range-buttons .buttons button {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  #daily span.clusters-desc {
    font-size: 70% !important;
  }
}
