/*******************************
         Site Overrides
*******************************/

.ui.vertical.menu > .active.item {
    background-color: @primaryColor;
    color: #ffffff;
}
.ui.vertical.menu > .active.item:hover {
    background-color: @primaryColor;
    color: #ffffff;
}
