.Savings {
  margin-bottom: 3rem;
}

.Savings .yesterdays-estimates {
  margin-top: -1rem;
  opacity: 0.7;
}

.Savings .no-data-available {
  font-size: 1.1em;
  margin: 2rem;
  font-weight: bold;
  line-height: 2rem;
  opacity: 0.5;
}

.Savings .smartstor-status {
  font-size: 1.1em;
  margin: 2rem;
  font-weight: bold;
}
.Savings .smartstor-status.enabled {
  color: #009245;
}
.Savings .smartstor-status.disabled {
  color: rgba(193, 39, 45, 0.9);
}

.Savings .no-tariff-set {
  font-size: 1.1em;
  margin: 2rem;
  font-weight: bold;
  opacity: 0.5;
}

.Savings .statistic {
  margin-top: 0;
  margin-bottom: 1rem !important;
}
